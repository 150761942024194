import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`marcas-veiculo?${parametros}`)
  },
  async inserir (dados) {
    return await axios.post('marcas-veiculo', dados)
  },
  async buscar (dados) { 
    return await axios.get(`marcas-veiculo/${dados}`)
  },
  async atualizar (id, dados) {
    return await axios.put(`marcas-veiculo/${id}`, dados)
  },
}
