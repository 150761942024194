<template> 
      <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <div v-if="$temPermissao('marcas-veiculo-insercao')">
          <btn-adicionar 
          to="/veiculos/marca/adicionar"
        />
        </div>
      </v-col>
    </v-row>

    
    <v-row
      v-if="$temPermissao('marcas-veiculo-listagem')"
      class="mt-4"
    >
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="marcas"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="carregarMarcaVeiculo"
          >
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status ? 'Ativo' : 'Inativo' }}
            </template>

            <template
              v-slot:[`item.actions`]="{ item }"
              class="text-right"
            >
            <div v-if="$temPermissao('marcas-veiculo-alteracao')">
              <table-btn-editar :href="`/veiculos/marca/${item.id}/editar`" /> 
            </div>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
  </template>
  
<script>
  import marcas from '@/api/marcas'
    export default {
      name: 'ListarMarcaVeiculo',

      data () {
      return {
        tab: 'veiculos',
        carregandoBuscarVisibilidades: false,
        visibilidades: [],
        visibilidadeId: 1,
        cabecalho: [
          { text: '#', value: 'id' },
          { text: 'Descrição', value: 'descricao' }, 
          { text: 'Ações', value: 'actions', align: 'right' },
        ],
        marcas: [],
        paginacao: {},
        totalRegistros: 0,
        carregandoDeletar: false,
      }
    },
      methods: {
        async carregarMarcaVeiculo () {
        try {
          this.carregando = true

          const resposta = await marcas.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
          })

          this.marcas = resposta.data.data
          this.totalRegistros = resposta.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },
      },
    }
  </script>
  